module.exports = {
  background: '#FFFFFF',
  backgroundLight: '#fae3eb',
  backgroundDark: '#f0e6f6',

  // Gatsby
  // primary: '#7c37ad',
  // primaryLight: '#ae66df',
  // primaryDark: '#4b007d',

  // 2
  primary: '#3059ad',
  primaryLight: '#5785e0',
  primaryDark: '#3d4c6a',

  // secondary: '#ff4081',
  // secondaryLight: '#ff79b0',
  // secondaryDark: '#c60055',

  // 1
  secondary: '#fa34db',
  secondaryLight: '#f87ae4',
  secondaryDark: '#e30cc2',

  // 1
  tertiary: '#ffbd59',
  tertiaryLight: '#f7c983',
  tertiaryDark: '#f6980a',

  gradient:
    'linear-gradient(132deg, rgba(254,165,111,1) 0%, rgba(251,94,179,1) 100%)',
};
