import React, { Fragment } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { ScrollingProvider } from 'react-scroll-section';
import 'react-tippy/dist/tippy.css';
import config from 'react-reveal/globals';
import colors from '../../colors';
import Helmet from './Helmet';
import SIFONN_PRO from '../fonts/SIFONN_PRO.woff';
import KOLLEKTIF from '../fonts/Kollektif.ttf';
import KOLLEKTIF_BOLD from '../fonts/Kollektif-Bold.ttf';
import KOLLEKTIF_BOLD_ITALIC from '../fonts/Kollektif-BoldItalic.ttf';
import KOLLEKTIF_ITALIC from '../fonts/Kollektif-Italic.ttf';

const GlobalStyle = createGlobalStyle`
*,
*::after,
*::before { 
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  }

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; 
  margin: 0;
  font-family: Kollektif, sans-serif;
  overflow-x: hidden;
}

@font-face {
  font-family: 'Sifonn Pro';
  font-style: normal;
  font-weight: normal;
  src: local('Sifonn Pro'), url(${SIFONN_PRO}) format('woff');
}

@font-face {
  font-family: 'Kollektif';
  font-style: normal;
  font-weight: normal;
  src: local('Kollektif'), url(${KOLLEKTIF}) format('ttf'), url(${KOLLEKTIF_BOLD}) format('ttf'), url(${KOLLEKTIF_BOLD_ITALIC}) format('ttf'), url(${KOLLEKTIF_ITALIC}) format('ttf');
}
`;

config({ ssrFadeout: true });

const Layout = ({ children }) => (
  <Fragment>
    <GlobalStyle />
    <ThemeProvider theme={{ colors }}>
      <ScrollingProvider>
        <Helmet />
        {children}
      </ScrollingProvider>
    </ThemeProvider>
  </Fragment>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
