import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Heading, Flex, Box, Text, Image } from 'rebass';
import TextLoop from 'react-text-loop';
import { SectionLink } from 'react-scroll-section';
import styled from 'styled-components';
import Section from '../components/Section';
import SocialLink from '../components/SocialLink';
import MouseIcon from '../components/MouseIcon';
import Triangle from '../components/Triangle';

const Background = () => (
  <div>
    {/*    <Triangle
      color="backgroundDark"
      height={['35vh', '80vh']}
      width={['95vw', '60vw']}
    />

    <Triangle
      color="secondary"
      height={['38vh', '80vh']}
      width={['50vw', '35vw']}
    />

    <Triangle
      color="primaryDark"
      height={['25vh', '35vh']}
      width={['75vw', '60vw']}
      invertX
    />

    <Triangle
      color="backgroundDark"
      height={['20vh', '20vh']}
      width={['100vw', '100vw']}
      invertX
      invertY
    />*/}
  </div>
);

const ProfilePicture = styled(Image)`
  width: 12%;
  min-width: 100px;
  border-radius: 50%;
  transition: all 0.25s ease-out;
`;

const centerHorizontally = { marginRight: 'auto', marginLeft: 'auto' };

const LandingPage = () => (
  <Section.Container
    id="home"
    Background={Background}
    backgroundColor="linear-gradient(132deg, rgba(254,165,111,1) 0%, rgba(251,94,179,1) 100%)"
  >
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          contentfulAbout {
            name
            roles
            socialLinks {
              id
              url
              name
              fontAwesomeIcon
            }
            profile {
              title
              image: resize(width: 250, quality: 100) {
                src
              }
            }
          }
        }
      `}
      render={data => {
        const { name, socialLinks, roles, profile } = data.contentfulAbout;

        return (
          <Fragment>
            <Heading
              textAlign="center"
              as="h1"
              color="primary"
              fontSize={[6, 7, 7, 8]}
              mb={[4, 3, 4]}
            >
              <ProfilePicture src={profile.image.src} alt={profile.title} />
              <div
                style={{ fontFamily: 'Sifonn Pro', color: '#3d4c6a' }}
              >{`${name}`}</div>
            </Heading>

            <Heading
              as="h2"
              color="primary"
              fontSize={[4, 5, 5, 5]}
              mb={[4, 3, 4]}
              textAlign="center"
              style={centerHorizontally}
            >
              <TextLoop interval={1800}>
                {roles.map(text => (
                  <Text
                    width={[300, 500]}
                    key={text}
                    style={{
                      fontFamily: 'Kollektif, sans-serif',
                      fontStyle: 'italic',
                      color: 'white',
                    }}
                  >
                    {text}
                  </Text>
                ))}
              </TextLoop>
            </Heading>

            <Flex alignItems="center" justifyContent="center" flexWrap="wrap">
              {socialLinks.map(({ id, ...rest }) => (
                <Box mx={3} fontSize={[5, 6, 6]} key={id}>
                  <SocialLink {...rest} />
                </Box>
              ))}
            </Flex>
            <SectionLink section="about">
              {({ onClick }) => <MouseIcon onClick={onClick} />}
            </SectionLink>
          </Fragment>
        );
      }}
    />
  </Section.Container>
);

export default LandingPage;
