import React from 'react';
import { Box, Image, Flex } from 'rebass';
import { StaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Gallery from 'react-photo-gallery';
import Section from '../components/Section';
import Triangle from '../components/Triangle';

const Background = () => (
  <div>
    <Triangle
      color="secondaryLight"
      height={['50vh', '20vh']}
      width={['50vw', '50vw']}
      invertY
    />

    <Triangle
      color="primaryDark"
      height={['20vh', '40vh']}
      width={['75vw', '70vw']}
      invertX
    />

    <Triangle
      color="backgroundDark"
      height={['25vh', '20vh']}
      width={['100vw', '100vw']}
    />
  </div>
);

const Works = () => (
  <Section.Container id="works" Background={Background}>
    <Section.Header name="iPad Works" label="works" />
    <StaticQuery
      query={graphql`
        query WorksQuery {
          contentfulAbout {
            works {
              width
              title
              height
              image {
                file {
                  url
                }
              }
            }
          }
        }
      `}
      render={data => {
        const { works } = data.contentfulAbout;
        const photos = works.map(work => {
          return {
            src: work.image.file.url,
            width: work.width,
            height: work.height,
          };
        });
        return (
          <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
            <Box width={[1, 1, 4 / 6]} px={[1, 2, 4]}>
              <Fade left>
                <Gallery photos={photos} />
              </Fade>
            </Box>
          </Flex>
        );
      }}
    />
  </Section.Container>
);

export default Works;
