import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Text, Box, Link, Flex } from 'rebass';
import Fade from 'react-reveal/Fade';
import SocialLink from './SocialLink';

const FooterContainer = styled.footer`
  min-width: 100%;
  max-width: 1366px;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: auto;
`;

const TextFooter = styled(Text)`
  color: ${props => props.theme.colors.primaryDark};

  & a {
    color: ${props => props.theme.colors.primaryDark};
  }
`;

const Footer = () => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        contentfulAbout {
          name
          roles
          socialLinks {
            id
            url
            name
            fontAwesomeIcon
          }
        }
      }
    `}
    render={data => {
      const { name, socialLinks } = data.contentfulAbout;

      return (
        <Box
          p={3}
          style={{
            background:
              'linear-gradient(132deg, rgba(254,165,111,1) 0%, rgba(251,94,179,1) 100%)',
          }}
        >
          <FooterContainer>
            <Fade left>
              <TextFooter>
                <Link
                  href="https://n11sh1.com/"
                  style={{
                    fontFamily: 'Sifonn Pro',
                    color: '#3d4c6a',
                    textDecoration: 'none',
                  }}
                >{`${name}`}</Link>
              </TextFooter>
            </Fade>
            <Flex>
              <Fade right>
                {socialLinks.map(({ id, ...rest }) => (
                  <Box mx={2} fontSize={4}>
                    <SocialLink {...rest} alt />
                  </Box>
                ))}
              </Fade>
            </Flex>
          </FooterContainer>
        </Box>
      );
    }}
  />
);

export default Footer;
